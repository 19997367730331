<template>
    <div>
        <main-modal :title="''" @closeModal="$emit('closeModal')">
            <div class="d-flex align-items-center position-relative">
                <div class="d-flex align-items-center justify-content-between w-100 mb-2" style="height: 20px">
                    <icon-star v-if="defaultCard" :size="20" class="start me-2" />
                    <h5 class="description-card position-absolute weight-600 mb-0" :class="{active: defaultCard}">{{$t('account.add_credit_card')}}</h5>
                <main-switch class="position-relative d-none d-sm-flex ms-auto my-3" :style="configurations.payment_methods.stripe_backend_implementation.active ? 'top: 0px' : 'top: 28px'" description="checkout.default" id="default" :value="defaultCard" @changeValue="(data)=>{defaultCard = data}"/>
                </div>
            </div>
            <h6 class="mb-0 d-sm-none">{{$t('account.fill_in_the_data_to_add_a_new_card_mobile')}}</h6>
            <h6 class="mb-0 d-none d-sm-block">{{$t('account.fill_in_the_data_to_add_a_new_card_desktop')}}</h6>
            <card-form isAccount :updateData="updateData" :default="defaultCard" :loading="loading" @closeModal="$emit('closeModal')" @errorInCard="openErrorProcesingCard"
            @setDefault="defaultCard = true">
                <main-switch class="my-3 d-sm-none px-0" description="checkout.default" id="default" :value="defaultCard" @changeValue="(data)=>{defaultCard = data}"/>
            </card-form>
        </main-modal>
        <!-- <error-procesing-card v-if="showError" @closeModal="showError = false" :code="code" /> -->
    </div>
</template>

<script>
import CardForm from '@/modules/checkout/components/payments/cards/CardForm.vue'
import MainModal from '@/common/components/modals/MainModal.vue'
// import ErrorProcesingCard from './ErrorProcesingCard.vue';
import MainSwitch from '../../../../common/components/switch/MainSwitch.vue';
import IconStar from '../../../../common/svg/iconStar.vue';
import { mapGetters } from 'vuex';

export default {
    name: "AddCreditCard",
    props: {
        updateData: {
            type: Object
        },
        loading: {
            type: Boolean
        }
    },
    computed:{
        ...mapGetters({
            configurations: 'common/configurations'
        })
    },
    components: {
        MainModal,
        CardForm,
        // ErrorProcesingCard,
        MainSwitch,
        IconStar
    },
    methods: {
        openErrorProcesingCard(data){
            this.code = data;
            this.showError = true;
        }
    },
    data(){
        return {
            showError: false,
            defaultCard: false,
            code: null,
        }
    }
}
</script>

<style scoped>
.description-card {
    transition: all 0.3s ease-in-out;
}
.active {
    transform: translateX(30px) !important;
}
.start {
    animation: star 0.5s;
}
@keyframes star {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.main-switch::v-deep > span {
    margin-left: 12px !important;
}
@media (max-width : 575px) {
    .card-form::v-deep .button-container {
        margin-top: 0px !important;
        justify-content: center !important;
    }
    .card-form::v-deep .card-container {
        padding-inline: 0 !important;
    }
    .card-form::v-deep .credit-card-container {
        width: 315px !important;
        margin-left: 12px;
    }
}
</style>
