<template>
    <main-modal class="card-history-modal" :title="'account.card_history_title'" @closeModal="$emit('closeModal')">
        <div class="d-flex align-items-center justify-content-between me-3" style="padding-right: 18px;">
            <span class="d-block size-17 mb-0 dark-main-navy">{{$t('account.card_history_desc')}}</span>
            <a v-if="selectedCards.length > 0" @click.prevent="selectedCards = []" class="clear-all position-relative hover-underline">
                <icon-trash :size="16" color="var(--blue-600)" class="ms-1" />
                <span class="size-15 d-none d-sm-flex">{{$t("cart.delete_selection")}}</span>
            </a>
        </div>
        <!-- desktop-version -->
        <table class="table d-none d-lg-block mt-4 align-middle">
            <thead>
                <tr>
                    <th class="card-info-size d-flex size-16 weight-600 ps-0">
                        <!-- <div class="me-3 pe-2">
                            <main-checkbox class="main-checkbox" :value="selectAll" :id="'all'" @change="selectAllCards"/>
                        </div> -->
                        <span class="size-18 ps-2">{{$t('account.card')}}</span> 
                    </th>
                    <th class="card-id-size d-none d-lg-table-cell text-center size-18 weight-600">{{$t('Numero de factura')}}</th>
                    <th class="card-amount-size text-center size-18 weight-600 ps-0 pe-4">{{$t('account.total_paid')}}</th>
                    <!-- <th class="card-hide-size card-hide-size-title text-end size-18 weight-600 text-center">{{$t('account.hide')}}</th> -->
                </tr>
            </thead>
            <tbody class="list d-block" style="width: fit-content; padding-right: 18px;">
                <tr v-for="card in cards" :key="card.id">
                    <td class="card-info-size d-flex align-items-center ps-0 pe-0">
                        <!-- <div class="me-4 pe-2">
                            <main-checkbox isList class="main-checkbox" :id="`'select-card-${card.id}`" :values="selectedCards" :value="card.id" @change="selectCard(card)"/>
                        </div> -->
                        <icon-visa v-if="card.card_token.card_brand.toLowerCase() == 'visa'" :size="70" class="card-logo-img"/>
                        <icon-mastercard v-if="card.card_token.card_brand.toLowerCase() == 'mastercard'" :size="70" class="card-logo-img"/>
                        <icon-discover  v-if="card.card_token.card_brand.toLowerCase() == 'discover'" :size="70" class="card-logo-img" />
                        <icon-troy  v-if="card.card_token.card_brand.toLowerCase() == 'troy'" :size="70" class="card-logo-img" />
                        <icon-union-pay  v-if="card.card_token.card_brand.toLowerCase() == 'unionpay'" :size="70"  class="card-logo-img"/> 
                        <icon-diners-club  v-if="card.card_token.card_brand.toLowerCase() == 'dinersclub'" :size="58" class="card-logo-img"/>
                        <icon-j-c-b  v-if="card.card_token.card_brand.toLowerCase() == 'jcb'" :size="70" class="card-logo-img"/>
                        <icon-american-express v-if="card.card_token.card_brand.toLowerCase() == 'amex'" :size="100" class="card-logo-img"/>
                        <div class="d-flex flex-column ps-1 ms-3">
                            <div class="size-16">
                                <span class="d-none d-xxl-inline  color-dark-gray-200 text-capitalize">{{card.card_token.card_brand | textFormat}}</span>
                                <span> **** {{card.card_token.card_last_four_digits}}</span>
                                <span class="ml-2 mr-2 font-weight-light color-dark-gray-200 mx-1 px-1"> | </span>
                                <span class="weight-500">{{$t("checkout.expiration_date")}} {{fMonth(card.card_token.card_expiry_month)}} / {{card.card_token.card_expiry_year}}</span>
                            </div>
                            <span>
                                <span class="d-none d-lg-inline size-16 color-dark-gray-200 weight-300">{{$t("account.use_date")}}:</span> 
                                <b data-toggle="tooltip" data-placement="top" :title="$t('account.use_date')" class="used-date cursor-pointer size-15 color-dark-gray-200 ms-1">
                                    {{ fDate(card.order_primary.created_at) }}
                                </b>
                            </span>
                        </div>
                    </td>
                    <td class="card-id-size d-none d-lg-table-cell text-center">
                        <span class="d-flex justify-content-center size-17 color-blue-600 cursor-pointer">#{{card.order_primary.global_token_id}}</span>
                    </td>
                    <td class="card-amount-size">
                        <span class="d-flex justify-content-center size-17 ps-0 pe-4">{{(card.amount) | moneyFormat}}</span>
                    </td>
                    <!-- <td class="card-hide-size d-flex justify-content-center">
                        <icon-eye :size="27" :color="'var(--gray-1001)'"/>
                    </td> -->
                </tr>
            </tbody>
        </table>
        <!-- mobile-version -->
        <div class="mobile-version d-block d-lg-none w-100">
            <div v-for="card in cards" :key="card.id" class="card-info-mobile d-flex mt-3 pb-3 me-sm-3">
                <icon-visa v-if="card.card_token.card_brand.toLowerCase() == 'visa'" :size="70" class="card-logo-img"/>
                <icon-mastercard v-if="card.card_token.card_brand.toLowerCase() == 'mastercard'" :size="70" class="card-logo-img"/>
                <icon-discover  v-if="card.card_token.card_brand.toLowerCase() == 'discover'" :size="70" class="card-logo-img" />
                <icon-troy  v-if="card.card_token.card_brand.toLowerCase() == 'troy'" :size="70" class="card-logo-img" />
                <icon-union-pay  v-if="card.card_token.card_brand.toLowerCase() == 'unionpay'" :size="70"  class="card-logo-img"/> 
                <icon-diners-club  v-if="card.card_token.card_brand.toLowerCase() == 'dinersclub'" :size="58" class="card-logo-img"/>
                <icon-j-c-b  v-if="card.card_token.card_brand.toLowerCase() == 'jcb'" :size="70" class="card-logo-img"/>
                <icon-american-express v-if="card.card_token.card_brand.toLowerCase() == 'amex'" :size="100" class="card-logo-img"/>
                <div class="d-flex flex-column justify-content-between flex-grow-1 ms-3">
                    <div class="d-flex" style="line-height: 16px;">
                        <span class="size-15 color-blue-600 weight-600">#{{card.order_primary.global_token_id}}</span>
                        <!-- <span class="icon-eye-container cursor-pointer ms-auto">
                            <icon-eye color="var(--gray-1001)" style="transform: translateY(-2px) !important;"/>
                        </span> -->
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                        <div class="d-flex subtitle-14" style="line-height: 12px;">
                            <span> **** {{card.card_token.card_last_four_digits}} </span><span class="weight-500 ms-auto">Expired {{fMonth(card.card_token.card_expiry_month)}} / {{card.card_token.card_expiry_year}}</span>
                        </div>
                        <div class="d-flex" style="line-height: 22px;">
                            <span class="used-date-mobile subtitle-14 cursor-pointer color-dark-gray-200"> 10 Oct 2021 </span>
                            <span class="size-15 weight-600 color-dark-main-navy ms-auto">
                                {{(card.amount) | moneyFormat}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main-pagination class="mt-4 mb-3 me-md-4 me-lg-5 pe-lg-3 pe-xl-0" :total="lastPage.last_page" :current="pageNumber" @setPage="getHistoryCards"/>
        <!-- <ul class="list-card p-0 m-0 mb-4 col-12"> -->
        <!--    <confirm-delete-card v-if="showDelete" @confirmDelete="deleteCard" @closeModal="showDelete = false;"/>
                <single-payment v-if="showSinglePayment" @closeModal="showSinglePayment = false" @confirm="confirm" :title="'checkout.single_payment'" :description="'checkout.this_payment_method_is_unique'"/> -->
        <!-- </ul> -->
    </main-modal>
</template>


<script>

import MainModal from '@/common/components/modals/MainModal.vue'
import { mapGetters } from "vuex"
import { http } from "@/common/services/web-backend.js";
// import IconEye from "@/common/svg/iconEye.vue";
// import MainCheckbox from '../../../../common/components/checkbox/MainCheckbox.vue';
import MainPagination from '../../../../common/components/pagination/MainPagination.vue';
import IconTrash from '../../../../common/svg/iconTrash.vue';


import IconVisa from '@/modules/checkout/assets/svg/iconVisa.vue'
import iconAmericanExpress from '../../../checkout/assets/svg/iconAmericanExpress.vue';
import IconMastercard from '@/modules/checkout/assets/svg/iconMastercard.vue'

import iconDiscover from '../../../checkout/assets/svg/iconDiscover.vue';
import iconDinersClub from '../../../checkout/assets/svg/iconDinersClub.vue';
import iconJCB from '../../../checkout/assets/svg/iconJCB.vue';
import iconUnionPay from '../../../checkout/assets/svg/iconUnionPay.vue';
import iconTroy from '../../../checkout/assets/svg/iconTroy.vue';

import { CustomerService } from '../../services/customer-service';
const service = new CustomerService()

export default {
    components: { 
        IconVisa,
        iconDinersClub,
        iconDiscover,
        iconJCB,
        iconTroy,
        iconUnionPay,
        iconAmericanExpress,
        // IconEye,
        IconMastercard,
        MainModal, 
        // MainCheckbox,
        MainPagination,
        IconTrash, 
    },
    name: "CardHistory",
    // props: {
    //     cards: {
    //         type: Array
    //     }
    // },
    computed:{
        ...mapGetters({
            cardSelected: "checkout/cardSelected",
            normalPayments: "checkout/normalPayments",
            singlePaymentActive: "checkout/singlePaymentActive",
            paypalSelected:  "checkout/paypalSelected",
            creditSelected: "checkout/creditSelected",
            orderConfirm: "checkout/orderConfirm",

        }),
    },
    data() {
        return {
            cards: [],
            newCardSelect:null,
            showSinglePayment: false,
            card: {},
            showDelete: false,
            deleteId: null,
            selectAll: false,
            selectedCards: [],
            lastPage: null,
            pageNumber: null,
        }
    },
    watch: {
        async cardSelected(val){
            if(val.balance === 0){
                this.newCardSelect = null
            }
        },        
        normalPayments(val){
            if(val){
                this.newCardSelect = null
            }
        }
    },
    methods: {
        fDate(fechaISO) {
            const date = new Date(fechaISO);
            const daysWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

            const dayWeek = daysWeek[date.getUTCDay()];
            const month = months[date.getUTCMonth()];
            const year = date.getUTCFullYear();

            return `${dayWeek}, ${date.getUTCDate()} ${month} ${year}`;
        },
    

        fMonth(month) {
            return month < 10 ? '0' + month.toString() : month; 
        },
        async deleteCard(){
            try {
                await http.delete(`users/cards/${this.deleteId}`);
                this.showDelete = false
                this.$store.dispatch('account/getCards')
            } catch (error) {
                console.log(error);
            }
        },
        editCard(card){
            this.$emit("editCard", card)
        },
        confirm(){
            this.$store.commit("checkout/RESET_PAYMENTS")
            this.setCard(this.card)
            this.showSinglePayment = false
        },
        selectAllCards(){
            this.selectAll = !this.selectAll;
            if (this.selectAll) {
                this.selectedCards = this.cards.map(card=>card.id);
            }else {
                this.selectedCards = [];
            }
        },
        selectCard(card){
            let index = this.selectedCards.findIndex(id => id == card.id)
            
            if(index == -1){
                this.selectedCards.push(card.id);
            }else{
                this.selectedCards.splice(index, 1)
            }
        },
        async getHistoryCards(page){
            try {
                this.loading = true                
                const res = await service.getPaymentCards( page || 1)
                this.lastPage = res
                this.pageNumber = res.current_page
                const filterRes = res.data.filter((data) => data.card_token !== null)
                this.cards = filterRes
            } catch (error) {
                this.$store.commit('common/SHOW_MODAL_ERROR', {
                    show: true, 
                    title: `Ocurrio un error obteniendo los datos.`, 
                    description: 'error'
                })
            }finally{
                this.loading = false       
            }
        },
    },
    created(){
        this.getHistoryCards()
    },
}
</script>

<style scoped>
    .modal-main-container::v-deep > .modal-main { /* remove components style rules*/
        max-width: none !important;
    }
    .main-checkbox::v-deep label span {
        border-radius: 4px !important;
    }
    th, td {
        border: 0 !important;
    }
    tr:not(:last-child), thead {
        border-bottom: 1px solid var(--gray-100);
    }
    tr:last-child {
        border-bottom: 1px solid var(--gray-100);
    }
    tbody {
        height: 339px;;
        overflow-y: auto; 
    }
    .table > :not(:first-child) {
        border-top: 0;
    }
    .card-logo-img {
        width: 82px;
        height: 59px;

        border: 1px solid var(--gray-100);
        border-radius: 5px;
    }
    .card-info-size {
        width: 375px;
    }
    td.card-info-size{
        padding: 24px 0px;
    }
    .card-id-size {
        width: 155px;
    }
    .card-amount-size {
        width: 130px;
    }
    .card-hide-size {
        width: 70px;
    }
    .card-info-mobile:not(:last-child) {
        border-bottom: 1px solid var(--gray-100);
    }
    .icon-eye-container svg {
        transition: var(--transition-1);
    }
    .icon-eye-container:hover svg {
        fill: var(--light-main-red);
    }
    .mobile-version {
        height: 500px;
        overflow: scroll;
    }
    /* Pagination */
    .modal-main-container::v-deep h5 {
        font-weight: 700 !important;
    }
    .main-pagination::v-deep li{
        font-weight: 400 !important;
    }
    @media (min-width: 768px) {
        .modal-main-container::v-deep > .modal-main {
            width: 600px !important;
        }
    }
    @media (min-width: 992px) {
        .modal-main-container::v-deep > .modal-main {
            width: 1000px !important;
        } 
        .card-logo-img {
            width: 95px;
            height: 64px
        }
        .used-date {
            font-weight: 500;
        }
        .card-info-size {
            width: 458px;
        }
        .card-id-size {
            width: 175px;
        }
        .card-amount-size {
            width: 156px;
        }
        .card-hide-size {
            width: 88px;
        }
        .card-hide-size-title {
            padding-right: 8px;
        }
        
    }
    @media (min-width : 1200px) {
        .modal-main-container::v-deep > .modal-main {
            width: 1040px !important;
        }
        .card-logo-img {
            width: 95px;
            height: 64px
        }
        .card-info-size {
            width: 588px;
        }
        .card-id-size {
            width: 175px;
        }
        .card-amount-size {
            width: 180px;
        }
        .card-hide-size-title {
            padding-right: 11px;
        }
    }
    @media (max-width : 576px) {
        .modal-main-container::v-deep > .modal-main { /* remove components style rules*/
            margin-inline: 0;
        }
    }
    .clear-all > span {
        color: var(--blue-600);
        transition: all .15s ease-in-out !important;
    }
    .clear-all:hover > span {
        margin-right: 21px;
        color: var(--main-red) !important;
    }
    .clear-all > svg {
        position: absolute;
        top: -14px;
        right: 0px;
        transition: all .15s ease-in-out, fill .05s ease-in-out;
        width: 25px !important;
        opacity: 1;
    }
    .clear-all:hover > svg {
        color: var(--main-red) !important;
        opacity: 1;
    }
    @media (min-width : 576px) {
        .clear-all > svg{
            top: 2px;
            width: 18px !important;
            opacity: 0;
        }
    }
</style>
