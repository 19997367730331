<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249 174.878" :width="size + 'px'" :fill="color">
        <g id="Group_5703" data-name="Group 5703" transform="translate(-1699 -2281.948)">
            <path id="Path_2292" data-name="Path 2292" d="M206.363,37.561H9.548A9.047,9.047,0,0,0,.5,46.609V59.184H215.411V46.609A9.047,9.047,0,0,0,206.363,37.561Z" transform="translate(1698.5 2244.387)" fill="#212e3d"/>
            <path id="Path_2293" data-name="Path 2293" d="M.5,169.276a9.048,9.048,0,0,0,9.048,9.048H171.631V178.3a43.81,43.81,0,0,1,43.76-43.728h.021V88.727H.5Zm15.6-46.282a1.6,1.6,0,0,1,1.6-1.6H86.724a1.6,1.6,0,0,1,1.6,1.6v11.125a1.6,1.6,0,0,1-1.6,1.6H17.7a1.6,1.6,0,0,1-1.6-1.6Zm0,25.147a1.6,1.6,0,0,1,1.6-1.6H123.537a1.6,1.6,0,0,1,1.6,1.6v11.126a1.6,1.6,0,0,1-1.6,1.6H17.7a1.6,1.6,0,0,1-1.6-1.6Z" transform="translate(1698.5 2244.387)" fill="#212e3d"/>
            <path id="Path_2294" data-name="Path 2294" :class="[hover ? 'plus' : 'regular']" d="M215.414,144.216A34.112,34.112,0,1,0,249.5,178.353v-.025A34.112,34.112,0,0,0,215.414,144.216Zm16.846,37.237H218.536v13.711a3.123,3.123,0,0,1-6.247,0V181.453H198.578a3.132,3.132,0,0,1,0-6.26h13.711V161.482a3.123,3.123,0,0,1,6.247,0v13.711h13.711a3.13,3.13,0,0,1,.013,6.26Z" transform="translate(1698.5 2244.387)" fill="#5a8cdb"/>
        </g>
    </svg> 
</template>
<script>
export default {
    name: 'iconAddresses',
    props:{
        size: {
            type:Number,
            default: 20,
        },
        color: { 
            type: String,
            default: ''
        },
        hover: {
            type: Boolean
        }
    }
}
</script>
<style scoped>
    .st0{fill:#FEF7EA;}
	.st1{fill:#F9CC95;}
	.st2{fill:#F5B14D;}
    .plus{
        fill: var(--dark-main-red);
        transition: var(--transition-1);
    }
    .regular {
        transition: var(--transition-1);
    }
</style>
// <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 68.964 64.042" xml:space="preserve"  :width="size + 'px'" :fill="color">
//     <path id="Path_1212" data-name="Path 1212" d="M949.487,748.182a1.252,1.252,0,0,0-1.838,0l-18.793,19.626a3.429,3.429,0,0,0-.931,2.37v19.528a3.194,3.194,0,0,0,3.071,3.3h10.439a1.035,1.035,0,0,0,.995-1.07V779.2a2.916,2.916,0,0,1,2.8-3.016H951.9a2.916,2.916,0,0,1,2.8,3.016v12.738a1.034,1.034,0,0,0,.994,1.07h10.439a3.2,3.2,0,0,0,3.072-3.3V770.178a3.43,3.43,0,0,0-.932-2.37Z" transform="translate(-921.068 -738.062)" fill="#212e3d"/>
//     <path id="Path_1213" data-name="Path 1213" d="M973.487,760.207l-25.563-25.763a2.415,2.415,0,0,0-3.481.014l-24.8,25.349a2.76,2.76,0,0,0-.058,3.752,2.42,2.42,0,0,0,3.552.061l23.06-23.575,23.817,24a2.418,2.418,0,0,0,3.552-.088A2.761,2.761,0,0,0,973.487,760.207Z" transform="translate(-918.881 -733.71)" fill="#212e3d"/>
//     <path id="Path_1216" :class="[hover ? 'plus' : 'regular']" data-name="Path 1216" d="M1026.32,715.44a12.084,12.084,0,1,0,12.079,12.084A12.084,12.084,0,0,0,1026.32,715.44Z" transform="translate(-970.935 -677.065)" fill="#5a8cdb" stroke="#fff" stroke-width="3"/>
//     <path id="Path_1217" data-name="Path 1217" d="M1042.381,737.389a.97.97,0,0,1-.971.972h-4.255v4.256a.969.969,0,1,1-1.939,0V738.36h-4.255a.971.971,0,0,1,0-1.943h4.255v-4.256a.969.969,0,1,1,1.939,0v4.256h4.255A.972.972,0,0,1,1042.381,737.389Z" transform="translate(-980.801 -686.931)" fill="#fff"/>
// </svg>