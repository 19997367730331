<template>
    <section class="mt-0">
        <div class="row mt-4 mb-4">
            <div class="hover-back-arrow d-flex col-md-12 flex-wrap cursor-pointer mb-5 px-2">
                    <div class="d-flex align-items-start align-items-md-center mt-2 mt-md-0 me-3" @click="goBack">
                        <icon-arrow :size="25" style="transform: rotate(180deg);" :color="'var(--dark-gray-200)'"/>
                    </div>
                    <div class="d-flex flex-column align-items-start ms-1" @click="goBack" style="max-width: 86%;">
                        <h6 class="title-22 color-blue-700 mb-1">{{$t("account.my_payment_methods")}}</h6>
                        <h6 class="subtitle-16 mb-0">{{$t("account.my_payment_methods_description")}}.</h6>
                    </div>

                <div class="d-flex flex-column flex-sm-row align-items-end ms-auto mt-3 mt-xl-0">
                    <span class="color-dark-gray-300">{{$t('account.balance')}}</span>
                    <b class="color-green-600 weight-400 ps-2"> {{ 0.000 | moneyFormat}}</b>    
                </div>
            </div>
            <div class="d-flex flex-column flex-sm-row flex-wrap align-items-center col-md-12 px-3 px-sm-0 ms-sm-4">
                <h6 class="size-20 weight-600 me-auto me-sm-3 ms-sm-4 ps-sm-3 mb-0">{{$t('account.my_credit_debit_cards')}}</h6>
                <a @click.prevent="showHistory = true" href="#" class="view-history me-auto me-sm-0 ms-sm-3 size-15">{{$t('account.view_history')}}</a>
            </div>
            <div class="d-flex flex-wrap justify-content-center justify-content-lg-start col-md-12 ms-sm-3 ps-lg-1">
                <div class="add-card-container d-flex flex-column flex-sm-row ps-xl-4 mt-sm-4 ms-md-5 ms-xl-2">
                    <radio-button class="me-3 invisible"/>
                    <div class="add-card d-flex flex-column justify-content-center align-items-center" 
                        @click="selectedCard = null; showForm = true" @mouseover="hover = true" @mouseleave="hover = false"
                        :class="[hover ? 'add-card-hover' : '']" >
                        <icon-add-card :size="78" :hover="hover"/>
                        <h5 class="size-19 weight-600 mt-2 pt-1">{{ $t("account.add_card") }}</h5>
                    </div>
                </div>
                <template v-if="!loading">
                    <div v-for="(card, index) in cards" :key="card._id" :class="[index <= 1 ? 'first-row':'pt-3']" class="d-flex flex-column flex-sm-row ps-xl-4 mt-4 ms-md-5 ms-xl-2">
                        <radio-button class="me-3 mb-3" :checked="card.is_default" />
                        <card isAccount :labels="getCard(card)" :background="backgrounds[index]"
                        @delete="(card) => {selectedCard = card; showDelete = true;}" @edit="(card) => {selectedCard = card; showForm = true;}" :code="card.id" @default="(code)=>{setDefaultCard(code)}"/>
                    </div>
                </template>
                <template v-else>
                    <div class="d-flex flex-column flex-sm-row ps-xl-4 mt-4 ms-md-5 ms-xl-2" v-for="item in 5" :key="item">
                        <radio-button class="me-3"/>
                        <skeleton :borderRadius="15"/>
                    </div>
                </template>
            </div>
            <hr class="ms-sm-2" style="margin: 4rem 0px;">
            <div class="row align-items-start justify-content-between flex-wrap px-4 px-lg-1 mx-0">
                <div class="col-md-7 d-flex flex-column flex-xl-row align-items-xl-center">
                    <h6 class="size-20 weight-600 ms-sm-5 mb-0 ps-sm-0">{{$t('account.my_deposits_and_available_credit_notes')}}</h6>
                    <a v-if="deposits.length > 0 || creditsNote.length > 0" @click.prevent="showDeposit = true" href="#" class="view-history size-15 me-sm-0 ms-sm-5 ms-xl-4">{{$t('account.add_deposit')}}</a>
                </div>
                <div v-if="deposits.length > 0 || creditsNote.length > 0" class="col-md-4 d-flex flex-column flex-lg-row justify-content-end ms-auto mb-lg-0">
                    <div class="d-flex align-items-center ms-auto ms-lg-0 me-lg-3">
                        <icon-circle :size="14" :color="'var(--green-600)'" class="me-2"/>
                        <h6 class="m-0 subtitle-15 text-nowrap">{{$t("checkout.available_deposits")}}</h6>
                    </div>
                    <div class="d-flex align-items-center ms-auto ms-lg-0 mt-1 mt-lg-0">
                        <icon-circle :size="14" :color="'var(--blue-600)'" class="me-2"/>
                        <h6 class="m-0 subtitle-15 text-nowrap">{{$t("checkout.credit_note")}}</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-12 px-4 px-lg-3">
                <deposits-available v-if="deposits.length > 0 || creditsNote.length > 0" :isAccount="true" style=" border-radius: 10px;" class="card-shadow my-auto ms-sm-5 me-0 p-4" />
                <without-results title="account.you_have_no_deposits_available" description="account.you_have_no_deposits_available_description" titleButton="account.add_deposit" 
                span="account.do_you_want_to_see_our" action="account.bank_accounts" @action="showBanks = true" class="mt-5" @click="showDeposit = true" v-else>
                    <template #main-icon>
                        <icon-no-deposits :size="80"/>
                    </template>
                </without-results>
                <bank-accounts v-if="showBanks" @closeModal="showBanks = false"/>
            </div>
            <confirm-delete-card :loading="loading" v-if="showDelete" @confirmDelete="deleteCard" @closeModal="showDelete = false;"/>
            <add-credit-card :loading="loading" v-if="showForm" @closeModal="showForm = false" :updateData="card" />
            <add-deposit v-if="showDeposit" @closeModal="showDeposit = false"/>
            <card-history v-if="showHistory" @closeModal="showHistory = false" :cards="dummyCards"/>
        </div>
    </section>
</template>

<script>
import iconArrow from '../../../common/svg/iconArrow.vue'
import IconAddCard from '../assets/svg/iconAddCard.vue'
import { mapGetters } from "vuex"
import Card from '../../checkout/components/payments/cards/Card.vue'
import DepositsAvailable from '../../checkout/components/payments/DepositsAvailable.vue'
import IconCircle from '../../../common/svg/iconCircle.vue'
import ConfirmDeleteCard from '../../checkout/components/modals/ConfirmDeleteCard.vue'

import { http } from "@/common/services/web-backend.js";
import AddCreditCard from "../components/modals/AddCreditCard.vue"
import AddDeposit from '../components/modals/AddDeposit.vue'
import CardHistory from '../components/modals/CardHistory.vue'
import RadioButton from '../../../common/components/radio/RadioButton.vue'
import WithoutResults from '../../../common/components/modals/WithoutResults.vue'
import BankAccounts from '../../checkout/components/modals/BankAccounts.vue'
import IconNoDeposits from '../assets/svg/iconNoDeposits.vue'
import Skeleton from '../../../common/components/skeleton/Skeleton.vue'

export default {
    name: "PaymentsMethods",
    components: {
        iconArrow,
        IconAddCard,
        IconCircle,
        Card,
        DepositsAvailable,
        ConfirmDeleteCard,
        AddCreditCard,
        CardHistory,
        RadioButton,
        WithoutResults,
        BankAccounts,
        IconNoDeposits,
        AddDeposit,
        Skeleton
    },
    computed: {
        ...mapGetters({
            cards: "account/cards",
            deposits: "checkout/deposits",
            creditsNote: "checkout/creditsNote",
            configurations: "common/configurations"
        }),
        select(){
            let filter = this.cards.filter(card => card.id == this.selectedCard)
            return filter[0] ? filter[0] : null
        },
        card(){
            let local_card;
            this.cards.map((card) => {
                if (card.id == this.selectedCard) {
                    local_card = card;
                }
            })
            return local_card;
        }
    },
    watch: {
        'configurations.payment_methods.stripe_backend_implementation.active'(){
            this.callGetCards()
        }
    },
    data() {
        return {
            dummyCards: [
                    { 
                        id:'299', full_name:"Adonys Veloz", brand:"visa", type:"debit", bank_name:"banco", card_number:"4103 9090 3904 9044", cvc:"687", 
                        expiry_year:2014, expiry_month:12, entity_id:2698, created_at:"2022-05-26T12:28:13.000000Z", updated_at:"2022-05-26T15:26:48.000000Z", 
                        deleted_at:null, default:true, selected: false,
                    },
                    {
                        "id":'298',
                        "full_name":"Kanye Lamar Martinez West",
                        "brand":"visa",
                        "type":"debit",
                        "bank_name":"banco",
                        "card_number":"4512 0030 0340 4532",
                        "cvc":"235",
                        "expiry_year":2022,
                        "expiry_month":12,
                        "entity_id":2698,
                        "created_at":"2022-05-26T12:27:53.000000Z",
                        "updated_at":"2022-05-26T15:26:48.000000Z",
                        "deleted_at":null,
                        "default":false,
                        selected: false,
                    },
                    {
                        "id":'301',
                        "full_name":"Freilyn Berbabé Veloz",
                        "brand":"mastercard",
                        "type":"debit",
                        "bank_name":"banco",
                        "card_number":"5194 4556 4564 5654",
                        "cvc":"123",
                        "expiry_year":2015,
                        "expiry_month":12,
                        "entity_id":2698,
                        "created_at":"2022-05-26T15:06:42.000000Z",
                        "updated_at":"2022-05-26T15:26:48.000000Z",
                        "deleted_at":null,
                        "default":false,
                        selected: false,
                    },
                    {
                        "id":'300',
                        "full_name":"Randy Veloz",
                        "brand":"mastercard",
                        "type":"debit",
                        "bank_name":"banco",
                        "card_number":"5412 3123 1231 2312",
                        "cvc":"231",
                        "expiry_year":3123,
                        "expiry_month":12,
                        "entity_id":2698,
                        "created_at":"2022-05-26T12:38:02.000000Z",
                        "updated_at":"2022-05-26T15:26:48.000000Z",
                        "deleted_at":null,
                        "default":false,
                        selected: false,
                    },
                    {
                        "id":' 269',
                        "full_name":"Benito Antonio Martínez Ocacion (directamente del epacio)",
                        "brand":"mastercard",
                        "type":"debit",
                        "bank_name":"banco",
                        "card_number":"5231 2301 0230 4572",
                        "cvc":"568",
                        "expiry_year":2022,
                        "expiry_month":12,
                        "entity_id":2698,
                        "created_at":"2022-05-25T20:35:57.000000Z",
                        "updated_at":"2022-05-26T15:26:48.000000Z",
                        "deleted_at":null,
                        "default":false,
                        selected: false,
                    }, 
                    {
                        "id":'1',
                        "full_name":"Adonys Veloz",
                        "brand":"visa",
                        "type":"debit",
                        "bank_name":"banco",
                        "card_number":"4103 9090 3904 9044",
                        "cvc":"687",
                        "expiry_year":2014,
                        "expiry_month":12,
                        "entity_id":2698,
                        "created_at":"2022-05-26T12:28:13.000000Z",
                        "updated_at":"2022-05-26T15:26:48.000000Z",
                        "deleted_at":null,
                        "default":true,
                        selected: false,
                    },
                    {
                        "id":'2',
                        "full_name":"Adonys Veloz",
                        "brand":"visa",
                        "type":"debit",
                        "bank_name":"banco",
                        "card_number":"4103 9090 3904 9044",
                        "cvc":"687",
                        "expiry_year":2014,
                        "expiry_month":12,
                        "entity_id":2698,
                        "created_at":"2022-05-26T12:28:13.000000Z",
                        "updated_at":"2022-05-26T15:26:48.000000Z",
                        "deleted_at":null,
                        "default":true,
                        selected: false,
                    },
            ],
            hover: false,
            backgrounds: ['white', '#2B457A', '#5972D6', '#26A4B8', '#FF534F'],
            selectedCard: null,
            //modals
            showForm: false,
            showDelete: false,
            showDeposit: false,
            showHistory: false,
            showBanks: false,
            loading: false
        }
    },
    methods: {       
        getCard(card){
            // let arr = card.card_number.split('')
            // arr.forEach((element, index) => {
            //     if (index > 4 && index < 14 && element.trim() !== '') {
            //     arr[index] = '*'
            //     }
            // })
            let obj = {
                cardNumber: "**** **** **** "+card.card_last_four_digits,
                brand : card.card_brand,
                cardName: card.cardholder_name,
                cardDate: `${card.card_expiry_month} / ${card.card_expiry_year}`,
                cardCvv: '***',
                defaultCard: card.is_default,
                card: card.id
            }
            return obj
        },
        async deleteCard(){
            try {
                this.loading = true
                if(this.configurations.payment_methods.stripe_backend_implementation.active){
                    await http.delete(`stripe/delete-payment-method?card_token_id=`+this.selectedCard);
                }else { 
                    await http.delete(`azul/process-data-vault-delete?card_token_id=`+this.selectedCard);
                }
                this.showDelete = false
                this.$store.dispatch('account/getCards')
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false
            }
        },
        async setDefaultCard(code){
            try {
                this.loading = true
                const id = {card_token_id: code};
                if(this.configurations.payment_methods.stripe_backend_implementation.active){
                    await http.post(`stripe/set-default-payment-method`, id);
                } else {
                    await http.post(`azul/set-default-token`, id);
                }
                this.showDelete = false
                this.$store.dispatch('account/getCards')
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false
            }
        },
        goBack(){
            this.$router.go(-1)
        },
        async callGetCards(){
            try {
                this.loading = true
                await this.$store.dispatch("account/getCards") 
            } catch (error) {
                console.log(error)
            } finally {
                this.loading = false
            }
        }
    },
    async created(){
        this.callGetCards()
    }
}
</script>

<style scoped>
    .hover-back-arrow svg {
        transition: var(--transition-1);
    }
    .hover-back-arrow:hover svg {
        fill: var(--blue-600);
    }
    .view-history:hover, .add-deposit:hover {
        text-decoration: underline !important;
    }
    .add-card {
        min-height: 224px;
        cursor: pointer;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23A5B2B7FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 15px;
        transition: var(--transition-1);
    }
    .add-card-hover {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23DAE5EAFF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    }
    .credit-card::v-deep {
        margin: 0 auto;
    }
    .deposits-available::v-deep > table > thead > tr > th, .deposits-available::v-deep > table > tbody > tr > td {
        padding: 10px;
    }
    .deposits-available::v-deep > table > thead {
        border-top: none !important;
    }
    .radio-button::v-deep [type=radio]:checked + label:after {
        top: 3px;
        left: 3px;
    }
    .radio-button::v-deep > label::before {
        width: 22px !important;
        height: 22px !important;
    }
    .radio-button::v-deep > label::after {
        width: 16px !important;
        height: 16px !important;
    }
    .credit-card-container::v-deep, .add-card, .skeleton::v-deep > .square {
        width: 352px !important;
        height: 230px !important;
    }
    /* max-width */
    @media (max-width : 991px) {
        .first-row {
            padding-top: 1rem;
        }
        .add-card-container {
            padding-top: 6px;
        }
    }
    /* min-width */
    @media (min-width : 576px) {
        .credit-card-container::v-deep, .add-card {
            width: 363px;
            height: 230px;
        }
    }
    @media (min-width : 992px) {
        .first-row, .add-card-container {
            padding-top: 0.35rem;
        }
        .first-row:nth-of-type(3) {
            padding-top: 1rem;
        }
    }
    @media (min-width : 1200px) {
        .first-row, .add-card-container {
            padding-top: 4px;
        }
    }
    @media (min-width : 1400px) {
        .first-row, .add-card-container {
            padding-top: 4px;
        }
        .first-row:nth-of-type(3) {
            padding-top: 4px;
        }
    }
    .without-results::v-deep > p{
        max-width: 430px;
    }
</style>
