<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 100 89.04">
        <g id="Deposit_icon" data-name="Deposit icon" transform="translate(-1402.951 -1870.307)">
            <rect id="Rectangle_1585" data-name="Rectangle 1585" width="82.205" height="13.112" rx="0.661" transform="translate(1414.93 1921.756)" fill="#f0f3f7"/>
            <path id="Path_2747" data-name="Path 2747" d="M-852.084,397.655a21.3,21.3,0,0,1-11.643-2.883,4.141,4.141,0,0,0-.522-.213q-.006-10.822-.009-21.647c5.558-1.313,11.084-2.8,16.684-3.9a20.661,20.661,0,0,1,17.222,3.793c2.841,2.164,5.816,4.152,8.727,6.222a3.593,3.593,0,0,1,.342.322c-.38.01-.758.028-1.137.028-8.165,0-16.33.077-24.491-.041a4.909,4.909,0,0,0-5.208,5.273c.051.74.269.874.938.868,3.544-.03,7.089-.021,10.632-.008.66,0,1.319.088,1.98.134a6.193,6.193,0,0,1,4.7,6.676,6.15,6.15,0,0,1-6.437,5.355c-1.9.006-3.8.02-5.7.03Z" transform="translate(2289.864 1501.77)" fill="#f5cbaa"/>
            <path id="Path_2748" data-name="Path 2748" d="M-838.568,385.606c-.659-.047-1.32-.132-1.98-.134-3.542-.013-7.087-.022-10.631.008-.668.006-.887-.128-.938-.868a4.909,4.909,0,0,1,5.208-5.273c8.161.118,16.326.041,24.491.041.378,0,.757-.018,1.136-.029a13.862,13.862,0,0,1,1.984.121,4.553,4.553,0,0,1,3.721,4.615q.009,14.572,0,29.145c0,.379.012.759.019,1.138q-.022,4.562-.045,9.119l-6.055-.025q0-15.756-.008-31.512c0-1.384-.39-1.809-1.748-1.922a3.027,3.027,0,0,1-2.814-2.83c-.1-1.306-.548-1.73-1.843-1.731q-4.842-.008-9.682,0C-838.026,385.468-838.324,385.392-838.568,385.606Z" transform="translate(2289.864 1501.77)" fill="#e1efff"/>
            <path id="Path_2749" data-name="Path 2749" d="M-864.257,372.912q0,10.826.009,21.647c-.005.982,0,1.964-.017,2.946a1.471,1.471,0,0,1-1.639,1.646q-5.988.018-11.975,0a1.445,1.445,0,0,1-1.592-1.6q-.013-13.589,0-27.179a1.466,1.466,0,0,1,1.647-1.638q5.938-.018,11.879,0c1.132,0,1.664.558,1.68,1.705C-864.252,371.265-864.258,372.089-864.257,372.912Z" transform="translate(2289.864 1501.77)" fill="#4979b2"/>
            <path id="Path_2750" data-name="Path 2750" d="M-852.084,397.654q3.042.008,6.082.013,0,12.9-.009,25.8l-6.053.024q-.024-4.559-.047-9.118Q-852.1,406.011-852.084,397.654Z" transform="translate(2289.864 1501.77)" fill="#e1efff"/>
            <path id="Path_2751" data-name="Path 2751" d="M-852.064,423.488l6.053-.025c2.654.012,5.308.032,7.961.031q8.2-.006,16.4-.031l6.055.025c1.458,0,2.915,0,4.373.014,1.124.009,1.757.583,1.736,1.554-.019.927-.639,1.482-1.712,1.483q-22.63.006-45.26,0c-1.074,0-1.694-.556-1.713-1.483-.019-.971.612-1.545,1.737-1.554C-854.98,423.489-853.522,423.492-852.064,423.488Z" transform="translate(2289.864 1501.77)" fill="#091d2b"/>
            <path id="Path_2752" data-name="Path 2752" d="M-821.655,423.463l-16.395.032c-2.654,0-5.307-.02-7.961-.032l.009-25.8c1.9-.01,3.8-.024,5.7-.029a6.153,6.153,0,0,0,6.437-5.355,6.2,6.2,0,0,0-4.7-6.677c.243-.213.541-.136.818-.136q4.84-.008,9.681,0c1.3,0,1.741.425,1.843,1.731a3.028,3.028,0,0,0,2.815,2.83c1.358.112,1.748.537,1.748,1.922Q-821.657,407.707-821.655,423.463ZM-833.823,402.2a6.085,6.085,0,0,0-6.094,6.077,6.085,6.085,0,0,0,6.077,6.094,6.085,6.085,0,0,0,6.094-6.077h0a6.127,6.127,0,0,0-6.078-6.094Z" transform="translate(2289.864 1501.77)" fill="#4979b2"/>
            <path id="Path_2753" data-name="Path 2753" d="M-833.824,402.2a6.087,6.087,0,0,1,6.076,6.1,6.085,6.085,0,0,1-6.1,6.075,6.085,6.085,0,0,1-6.075-6.1h0A6.15,6.15,0,0,1-833.824,402.2Z" transform="translate(2289.864 1501.77)" fill="#c4daf3"/>
            <ellipse id="Ellipse_35" data-name="Ellipse 35" cx="50" cy="4.1" rx="50" ry="4.1" transform="translate(1402.951 1951.146)" fill="#f0f4f9"/>
        </g>
    </icon-base>
</template>
<script>
import iconBase from '../../../../common/components/iconBase.vue'
export default {
    components: { iconBase },
    name: 'iconNoDeposits',
    props:{
    size: {
        type: Number,
        default: 20
    },
        color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
    }
}
</script>
