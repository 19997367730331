<template>
    <main-modal :title="'account.transfer_deposit'" @closeModal="$emit('closeModal')">
        <div class="row mt-2">
            <div class="col-md-12 mb-4">
                <span>{{$t('account.deposit_modal_description')}}</span>
            </div>
            <div class="col-md-6">
                <div class="d-flex">
                    <icon-dollar :size="15" class="me-2 mb-2" :color="'var(--dark-main-navy)'"/>
                    <label class="mb-2 subtitle-15 weight-600" id="cash-2">{{$t("account.enter_deposit_amount")}}</label>
                </div>
                <div class="input-group">
                    <span class="input-group-text" id="balance">
                        <icon-dollar :size="15" class="me-2" :color="'var(--dark-gray-200)'"/>
                    </span>
                    <input type="number" class="form-control" placeholder="RD$ 0.00" aria-label="RD$ 0.00" aria-describedby="balance">
                </div>
            </div>
            <div class="button-container col-md-6 d-flex align-items-end justify-content-end mt-2 mt-md-0">
                <main-button class="mt-2" :title="'checkout.see_bank_accounts'" @click="showBanks = true"/>
            </div>
            <div class="col-md-12 mt-4">
                <vue-dropzone class="cursor-pointer" ref="depositDropZone" id="imgDropZone" @vdropzone-max-files-exceeded="limit" :useCustomSlot="true" :options="depositOptions">
                    <div class="hover-dropzone d-flex flex-column align-items-center mt-4 py-5">
                        <icon-add-image :size="60" :color="'var(--gray-1001)'"/>
                        <h6 class="m-0 my-1 weight-600">{{$t('checkout.upload_image')}}</h6>
                        <span class="color-dark-gray-200 weight-500">{{$t('checkout.drag_or_upload_your_voucher_here')}}</span>
                    </div>
                </vue-dropzone>
            </div>
            <bank-accounts v-if="showBanks" @closeModal="showBanks = false"/>
        <!-- <single-payment v-if="showSinglePayment" @closeModal="cancel" @confirm="confirm" :title="'checkout.single_payment'" 
        :description="'checkout.msg_have_a_single_payment_method_selected'"/>
        <all-paid v-if="showAllPaid && !depositExpressSelected.balance > 0" @closeModal="showAllPaid = false" @confirm="reset"/> -->
    </div>
    </main-modal>
</template>

<script>
import MainModal from '@/common/components/modals/MainModal.vue'
import MainButton from '@/common/components/buttons/MainButton.vue'
import vueDropzone from 'vue2-dropzone'
import IconDollar from '@/modules/checkout/assets/svg/iconDollar.vue'
import IconAddImage from '@/common/svg/iconAddImage.vue'
import BankAccounts from '../../../checkout/components/modals/BankAccounts.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    name:'AddDeposit',
    components: {
        MainModal,
        MainButton,
        IconDollar,
        IconAddImage,
        vueDropzone,
        BankAccounts,
    },
    data(){
        return {
            depositOptions: {
                url: '/',
                maxFiles: 2,
                thumbnailWidth: 200,
                resizeQuality: .6,
                addRemoveLinks: true,
                autoProcessQueue: false,
                maxFilesize: 2,
                resizeWidth: 200,
                acceptedFiles: ".jpeg,.jpg,.png",
            },
            showBanks: false
        }
    },
    methods: {
        limit(){
            this.$refs.depositDropZone.removeAllFiles()
        },
    },
}
</script>

<style lang="scss" scoped>
    .modal-main-container::v-deep > .modal-main {
        max-height: none !important;
        min-height: 450px !important;
    }
    .vue-dropzone {
        border: none;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23C6D4DBFF' stroke-width='3' stroke-dasharray='16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 15px;
    }
    .hover-dropzone h6, .hover-dropzone span {
        font-family: 'Rubik';
    }
    .hover-dropzone::v-deep #Path_2174, .hover-dropzone::v-deep #Path_2175, .hover-dropzone::v-deep #Path_2176, .hover-dropzone::v-deep #Path_2177 {
        transition: var(--transition-1);
    }
    .hover-dropzone:hover::v-deep > svg > g > #Path_2176 {
        fill: var(--blue-600);
    }
    .hover-dropzone::v-deep:hover #Path_2174, .hover-dropzone::v-deep:hover #Path_2175, .hover-dropzone::v-deep:hover #Path_2177 {
        opacity: 0.6;
    }
    
    label{
        font-size: 15px;
        color: var(--main-navy); 
        font-weight: 600;
    }
    .form-control:disabled{
        background: transparent;
    }
    .form-control{
        padding: 0.1rem 3px;
    }
    .input-group-text{
        background: transparent;
        border-color: var(--gray-100) !important;
        border-radius: 7px;
        padding-right: 0px;
    }
    input::placeholder{
        font-size: 14px;
    }
    input:focus{
        box-shadow: none;
    }
    input{
        height: 38px !important;
        border-color: var(--gray-100) !important;
        border-radius: 7px;
        border: 1px solid;
        width: 100%;
        font-size: 14px;
        border-left: 0;
        background-color: none  !important;
    }

</style>